<template>
    <main>

        <section class="bg-white pt-5 pt-lg-0">
            <div class="container-fluid wrapper-medium hero-promo">
                <div class="row">
                    <div class="col-12 pt-md-5">
                        <div class="hero-promo-content">
                            <h1 class="mb-4 anim-load1">
                                TAYC-R is a questionnaire, that helps those working with families to understand and explore changes in the relationship between parents and their children.
                            </h1>
                            <div class="anim-load3 mb-5">
                                <b-link v-if="userSignedIn !== false" class="btn btn-lg btn-warning" to="/dashboard">Go to Dashboard</b-link>
                                <b-link v-if="userSignedIn === false" class="btn btn-lg btn-warning" to="/register-interest">Register interest</b-link>
                                <b-link v-if="userSignedIn === false" class="btn btn-link ml-2" to="/sign-in">Sign in</b-link>
                            </div>
                        </div>
                        <figure v-rellax="{speed: -2}" aria-hidden="true">
                            <img width="1084" height="281" src="/static/img/home-hero-family1.png" alt="">
                        </figure>
                    </div>
                </div>
            </div>

        </section>

        <section class="bg-primary text-white pt-5 pb-5">
            <div class="container-fluid wrapper-medium">
                <div class="row">
                    <div class="col-12 text-center mb-5">
                        <h2 class="h2">How it works</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <h3 class="mb-3">1. Create family profiles</h3>
                        <p>Build an individual record for each family to easily organise your caseload.</p>
                    </div>
                    <div class="col-12 col-md-4">
                        <h3 class="mb-3">2. Invite families to participate.</h3>
                        <p>Send a therapeutic questionnaire directly to families by email for them to complete in their own time.</p>
                    </div>
                    <div class="col-12 col-md-4">
                        <h3 class="mb-3">3. Receive the results</h3>
                        <p>Results of therapeutic questionnaires will be displayed as a graph and a report, allowing you to manage all results easily within your dashboard.</p>
                    </div>
                    <div class="col-12 text-center my-5">
                        <b-link class="btn btn-lg btn-warning" to="/register-interest">Register interest</b-link>
                    </div>
                </div>
            </div>
        </section>

        <!--<section class="bg-white pt-5 pb-5">
      <div class="container-fluid wrapper-small">
        <div class="row pt-5">
            <div class="col-12 text-center mb-5">
                <h2 class="h2 mb-3">Become a better therapist</h2>
                  <p>The TAYC-R site enables you to capture snapshots of families’ relational health over time, helping to understand which support may be most relevant at a given point. By comparing results over time you will be able to better understand which support is effective and where tailoring may be necessary.</p>
        <b-link class="btn btn-lg btn-warning" to="/register-interest">Register interest</b-link>
        </div>
        </div>
        </div>
        </section>-->

        <Footer />

    </main>
</template>

<script>
export default {
  name: 'Home',
  computed: {
    userSignedIn () {
      if (this.$store.state.user === null || this.$store.state.user.email === null || this.$store.state.user.email === '') {
        return false
      } else {
        return true
      }
    }
  },
  created: function () {
    this.$store.state.loadingProgress = false
    this.$store.state.appSection = 'brochure'
  },
  beforeDestroy() {
  },
  methods: {
  }
}
</script>
